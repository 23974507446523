<template>
  <div
    class="sf-product-card"
    :key="`${[product.sku]}-in-wishlist-${numberOfItemsInWishlist}`"
    :class="{ 'login-with': isAuthenticated }"
  >
    <!-- <div class="sf-product-card__multiple-price" v-show="multiplePrices > 1">
      {{ `X ${multiplePrices}` }}
    </div> -->
    <div class="sf-top-btn relative">
      <SfButton
        class="sf-button--pure sf-product-card__wishlist-icon sf-button"
        @click="addItemToWishlist(product)"
      >
        <nuxt-img
          :src="
            isInWishlist
              ? '/productpage/wishlist-icon-fill.svg'
              : '/productpage/wishlist-icon.svg'
          "
          alt="wishlist"
        />
      </SfButton>
      <SfButton
        :link="link"
        class="sf-product-card__add-button sf-circle-icon sf-button"
      >
        <nuxt-img src="/icons/cart.svg" alt="cart" />
      </SfButton>
    </div>
    <div class="sf-product-card__image-wrapper">
      <SfButton
        :link="link"
        class="sf-button--pure sf-product-card__link"
        data-testid="product-link"
        aria-label="Go To Product"
      >
        <template v-if="Array.isArray(image)">
          <SfImage
            v-for="(picture, key) in image.slice(0, 2)"
            :key="key"
            class="sf-product-card__picture"
            :src="picture"
            :alt="imageAlt"
            :width="imageWidth"
            :height="imageHeight"
          />
        </template>
        <div v-else>
          <nuxt-img
            v-if="image"
            :src="image"
            :alt="imageAlt"
            :width="imageWidth"
            :height="imageHeight"
          />
          <nuxt-img
            v-else
            class="product-image-placeholder"
            src="/icon.png"
            :alt="imageAlt"
            width="176"
            height="176"
          />
        </div>
      </SfButton>
    </div>
    <slot name="title" v-bind="{ title, link }">
      <SfButton
        :link="link"
        class="sf-button--pure sf-product-card__link card__title"
        data-testid="product-link"
      >
        <span>
          {{ title }}
        </span>
      </SfButton>
    </slot>
    <slot
      name="price"
      v-if="isAuthenticated"
      v-bind="{ specialPrice, regularPrice }"
    >
      <client-only>
        <div class="sf-product-card__price__container">
          <div v-show="npp" class="sf-product-card__price__npp" />
          <SfPrice
            v-if="!!specialPrice"
            :class="{ 'display-none': !regularPrice }"
            class="sf-product-card__price"
            :regular="$fc(productPrice)"
          />
        </div>
      </client-only>
    </slot>
    <client-only>
      <div v-if="stockStatus" class="stock-status">
        <div class="stock-status__dot" :class="colorClass(stockStatus)" />
        <div class="stock-status__label">{{ stockStatus }}</div>
      </div>
    </client-only>
  </div>
</template>

<script>
import { SfPrice, SfImage, SfButton } from '@storefront-ui/vue';
import { computed, useContext } from '@nuxtjs/composition-api';
import { productGetters } from '@gemini-vsf/composables';
import {
  useUiState,
  useUserWishlists,
  useUiNotification,
  useUser,
} from '~/composables';
import { getDiscountedPrice } from '~/helpers/product/productDiscounts';

export default {
  name: 'SfProductCardCustom',
  components: {
    SfPrice,
    SfImage,
    SfButton,
  },
  props: {
    product: {
      type: [Array, Object, String],
      default: '',
    },
    image: {
      type: [Array, Object, String],
      default: '',
    },
    imageWidth: {
      type: [String, Number],
      default: '100%',
    },
    imageHeight: {
      type: [String, Number],
      default: 'auto',
    },
    imageAlt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    stockAvailability: {
      type: Object,
      default: null,
    },
    npp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { toggleWishlistModal } = useUiState();
    const {
      app: { $cookies },
    } = useContext();
    const isAuthenticated = $cookies.get('vsf-customer');
    const {
      itemsInWishlist,
      removeItemFromWishlist,
      currentWishlistUid,
      numberOfItemsInWishlist,
    } = useUserWishlists();
    const { send: sendNotification } = useUiNotification();

    const { user } = useUser();
    const isA50 = computed(() =>
      user?.value?.segments?.includes(
        'grn:customer:customer_group::65b3c2ddc1b84cf9cd4e64ac'
      )
    );

    const productPrice = computed(() => {
      const getFloat = (value) => {
        if (typeof value === 'string') {
          return (
            Number.parseFloat(
              value.replace(/[^\d,.]/g, '').replaceAll(',', '.')
            ) || 0
          );
        }
        return value;
      };

      const specialPrice = getFloat(
        productGetters.getPrice(props.product)?.special
      );
      const regularPrice = getFloat(
        productGetters.getPrice(props.product)?.regular
      );

      const discountedPrice = getDiscountedPrice(regularPrice);

      return isA50.value ? discountedPrice : specialPrice || regularPrice;
    });

    const multiplePrices = computed(
      () =>
        props?.product?.configurable_product_options_selection?.variant
          ?.venditamultiplidiarticoliweb ||
        props?.product?.venditamultiplidiarticoliweb ||
        1
    );

    const isInWishlist = computed(() =>
      itemsInWishlist.value &&
      currentWishlistUid.value &&
      itemsInWishlist.value[currentWishlistUid.value]
        ? Object.keys(itemsInWishlist.value[currentWishlistUid.value]).find(
            (i) => i.includes(props.product.sku)
          )
        : false
    );

    const colorClass = (stock) =>
      stock === 'Disponibile in magazzino'
        ? 'stock-status__dot--green'
        : 'stock-status__dot--red';

    const stockStatus = computed(() => {
      if (!props.stockAvailability) return null;

      let status = 'Non disponibile';

      props.product.list_sku_variants.forEach((sku) => {
        const stock = props.stockAvailability?.[sku];
        if (stock > 0) {
          status = 'Disponibile in magazzino';
        }
      });

      return status;
    });

    const addItemToWishlist = async (product) => {
      if (!isAuthenticated) {
        sendNotification({
          id: Symbol('no_user_no_wishlist'),
          message: `Per favore effettua l'accesso per utilizzare la wishlist.`,
          type: 'danger',
          icon: 'check',
          persist: false,
          title: 'Errore',
        });
        return;
      }
      await (isInWishlist.value
        ? removeItemFromWishlist({ product })
        : toggleWishlistModal(product));
    };

    return {
      addItemToWishlist,
      isInWishlist,
      props,
      isAuthenticated,
      multiplePrices,
      itemsInWishlist,
      currentWishlistUid,
      numberOfItemsInWishlist,
      stockStatus,
      colorClass,
      isA50,
      productPrice,
    };
  },
};
</script>

<style lang="scss">
.sf-product-card::after {
  display: var(--hidden);
}
.sf-product-card__image-wrapper {
  position: relative;
  a {
    height: auto;
  }
}
.sf-product-card {
  &__link {
    margin-top: auto;
    img {
      height: auto !important;
      width: auto !important;
      max-width: var(--w-full);
      max-height: 12.5rem;
      margin: auto;
    }
    .product-image-placeholder {
      filter: grayscale(100%);
      opacity: 0.2;
      margin: auto;
    }
  }
}
.short_description {
  margin-bottom: 0.5rem;
}
.sf-product-card {
  display: var(--flex);
  flex-direction: column;
  .stock-status {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    &__label {
      @include text-12x12-bold;
    }
    &__dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      &--green {
        background-color: var(--c-green);
      }
      &--yellow {
        background-color: var(--c-yellow);
      }
      &--red {
        background-color: var(--c-red);
      }
    }
  }
  &__multiple-price {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    z-index: 1;
    @include paragraph-regular;
    font-weight: bold;
    background-color: var(--c-white);
    padding: 0 1rem;
  }
  &__price {
    &__container {
      display: flex;
      align-items: center;
    }
    &__npp:after {
      content: '\2731';
      color: var(--c-green);
    }
  }
}
.login-with {
  .sf-price {
    margin-top: auto !important;
  }
}
.short_description,
.short_description p {
  /* autoprefixer: off */
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  /* will not be prefixed */
}
</style>
